exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("./../../../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-contact-js": () => import("./../../../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-dummies-available-js": () => import("./../../../src/pages/dummies/available.js" /* webpackChunkName: "component---src-pages-dummies-available-js" */),
  "component---src-pages-dummies-charlie-mccarthy-dummies-js": () => import("./../../../src/pages/dummies/charlie-mccarthy-dummies.js" /* webpackChunkName: "component---src-pages-dummies-charlie-mccarthy-dummies-js" */),
  "component---src-pages-dummies-danny-o-day-dummies-js": () => import("./../../../src/pages/dummies/danny-o-day-dummies.js" /* webpackChunkName: "component---src-pages-dummies-danny-o-day-dummies-js" */),
  "component---src-pages-dummies-slappy-dummies-js": () => import("./../../../src/pages/dummies/slappy-dummies.js" /* webpackChunkName: "component---src-pages-dummies-slappy-dummies-js" */),
  "component---src-pages-dummies-upgrades-js": () => import("./../../../src/pages/dummies/upgrades.js" /* webpackChunkName: "component---src-pages-dummies-upgrades-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-nice-words-thrown-our-way-js": () => import("./../../../src/pages/nice-words-thrown-our-way.js" /* webpackChunkName: "component---src-pages-nice-words-thrown-our-way-js" */),
  "component---src-pages-privacy-js": () => import("./../../../src/pages/privacy.js" /* webpackChunkName: "component---src-pages-privacy-js" */),
  "component---src-pages-promotional-index-js": () => import("./../../../src/pages/promotional/index.js" /* webpackChunkName: "component---src-pages-promotional-index-js" */),
  "component---src-pages-returns-js": () => import("./../../../src/pages/returns.js" /* webpackChunkName: "component---src-pages-returns-js" */),
  "component---src-pages-shipping-js": () => import("./../../../src/pages/shipping.js" /* webpackChunkName: "component---src-pages-shipping-js" */),
  "component---src-pages-sitemap-js": () => import("./../../../src/pages/sitemap.js" /* webpackChunkName: "component---src-pages-sitemap-js" */),
  "component---src-pages-throw-your-voice-js": () => import("./../../../src/pages/throw-your-voice.js" /* webpackChunkName: "component---src-pages-throw-your-voice-js" */),
  "component---src-pages-tt-things-to-throw-js": () => import("./../../../src/pages/tt-things-to-throw.js" /* webpackChunkName: "component---src-pages-tt-things-to-throw-js" */),
  "component---src-templates-collection-js": () => import("./../../../src/templates/Collection.js" /* webpackChunkName: "component---src-templates-collection-js" */),
  "component---src-templates-product-js": () => import("./../../../src/templates/product.js" /* webpackChunkName: "component---src-templates-product-js" */)
}

